.c-grid__container {
    display: flex;
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0 !important;
}

.c-grid__item {
    padding-bottom: 1.5rem;
    margin: 0;
    list-style-type: inherit;
    flex-basis: auto;
    width: 100%;
}

.c-grid__inner {
    position: relative;
    height: 100%;
}

.c-grid__item--auto {
    flex: 1
}

@media only screen and (min-height: 30em) and (min-width: 40em), print {
    .c-grid__container {
        margin-right: -1.5rem !important;
    }

    .c-grid__item {
        padding-right: 1.5rem;
    }

    .c-grid__item--sml-1 {
        width: 100%
    }

    .c-grid__item--sml-2 {
        width: 50%
    }

    .c-grid__item--sml-3 {
        width: 33.33%
    }

    .c-grid__item--sml-4 {
        width: 25%
    }

    .c-grid__item--sml-5 {
        width: 20%
    }

    .c-grid__item--sml-6 {
        width: 16.66%
    }

    .c-grid__item--sml-8 {
        width: 12.5%
    }
}

@media only screen and (min-width: 64em) and (min-height:36em),print {
    .c-grid__item--med-1 {
        width: 100%
    }

    .c-grid__item--med-2 {
        width: 50%
    }

    .c-grid__item--med-3 {
        width: 33.33%
    }

    .c-grid__item--med-4 {
        width: 25%
    }

    .c-grid__item--med-5 {
        width: 20%
    }

    .c-grid__item--med-6 {
        width: 16.66%
    }

    .c-grid__item--med-8 {
        width: 12.5%
    }
}

@media only screen and (min-width: 82em) and (min-height:48em),print {
    .c-grid__item--lrg-1 {
        width: 100%
    }

    .c-grid__item--lrg-2 {
        width: 50%
    }

    .c-grid__item--lrg-3 {
        width: 33.33%
    }

    .c-grid__item--lrg-4 {
        width: 25%
    }

    .c-grid__item--lrg-5 {
        width: 20%
    }

    .c-grid__item--lrg-6 {
        width: 16.66%
    }

    .c-grid__item--lrg-8 {
        width: 12.5%
    }
}